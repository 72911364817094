//partials
@import "varaibles";
@import "colors";
@import "fonts";
@import "typography";
@import "mixins";

//blocks
@import "blocks/header";
@import "blocks/headslide";
@import "blocks/about";
@import "blocks/history";
@import "blocks/news";
@import "blocks/footer";

//components
@import "component/buttons";

html, body {
  width: 100%;
  margin: 0;
  color: $dark;
  min-height: 100vh;
  font-family: "europec", serif;
  box-sizing: border-box;
}

body.fixed {
  overflow: hidden;
}

* {
  box-sizing: inherit;
}

a {
  color: $dark;
  text-decoration: none;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

h1 {
  font-family: EuropeC, serif;
  font-weight: 400;
}

.container {
  width: 100%;
  overflow: hidden;
  min-width: 375px;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 40px 0 40px;

  @media screen and (max-width: $breakpoint-sm) {
    padding: 0 24px 0 24px;
  }

  @media screen and (max-width: $breakpoint-xs) {
    padding: 0 15px 0 15px;
  }
}

.section-title {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 45px;
  color: $dark;
  text-transform: uppercase;
  @include font(30px, 27px, 400, 'europeExtendedc');

  @media (max-width: $breakpoint-sm) {
    margin-bottom: 25px;
  }

  &:before {
    content: "";
    width: 96px;
    height: 1px;
    margin-right: 10px;
    background-color: $dark;

    @media (max-width: $breakpoint-xs) {
      width: 28px;
    }
  }

  &:after {
    content: "";
    width: 454px;
    height: 1px;
    margin-left: auto;
    background-color: $dark;

    @media (max-width: $breakpoint-sm) {
      width: 96px;
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    @include font(16px, 14px);
  }
}

.two-column {
  display: flex;
  gap: 0 30px;

  @media (max-width: $breakpoint-sm) {
    flex-direction: column;
    gap: 59px 30px;
  }

  @media (max-width: $breakpoint-xs) {
    gap: 25px 30px;
  }

  &--reverse {
    flex-direction: row-reverse;

    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
    }
  }
}




