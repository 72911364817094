.history {
  margin-top: 23px;
  margin-bottom: 45px;

  &__accordion {
    flex-grow: 1;
  }

  &__accordion-hidden {
    display: flex;
    flex-direction: column;
    gap: 56px;
  }

  &__accordion-toggle p {
    margin-right: 13px;
  }

  &__accordion-caption {
    margin-left: auto;
  }

  &__img {
    height: 242px;
    object-fit: cover;
  }

  &__item {
    position: relative;
    padding-left: 124px;
    width: 600px;
    overflow: visible;

    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }

    &:before {
      content: "";
      position: absolute;
      top: 90px;
      left: 40px;
      height: calc(100% - 60px);
      width: 5px;
      background-color: $primary;
      border-radius: 10px;

      @media (max-width: $breakpoint-sm) {
        top: 60px;
        left: 30px;
        height: calc(100% - 60px);
      }
    }
  }

  &__order {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    top: 0;
    left: 10px;
    color: white;
    border-radius: 100%;
    background-color: $primary;
    @include font(30px, 30px);

    @media (max-width: $breakpoint-sm) {
      width: 44px;
      height: 44px;
    }

    span {
      margin-top: 5px;
    }
  }

  &__date {
    margin-bottom: 16px;
  }

  &__img {
    width: 395px;
    height: auto;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 10px;

    @media (max-width: $breakpoint-sm) {
      width: 100%;
      height: 242px;
    }
  }

  .ac {
    display: flex;
    flex-direction: column;
    gap: 56px;
    margin-top: 0;
    border: none;
    background-color: transparent;
  }

  .ac .ac-trigger {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0;
    margin-bottom: 50px;
    font-family: 'EuropeC', serif;
    font-weight: 400;
    color: $dark;
  }

  .ac .ac-trigger::after {
    content: "";
    position: relative;
    top: auto;
    right: auto;
    width: 24px;
    height: 24px;
    background: url("../img/icons/icon-arrow-down.svg") no-repeat center center;
    transform: rotate(180deg);
    transition: all .3s ease;
  }
  .ac.is-active > .ac-header .ac-trigger::after {
    content: "";
    transform: rotate(0deg);
  }
}

.js-accordion--opened {
  .history__accordion-toggle:after {
    transform: rotate(0);
  }
}
