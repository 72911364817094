.button {
  position: relative;
  display: flex;
  align-items: center;
  padding: 13px 16px;
  color: $dark;
  border-radius: 10px;
  background-color: white;
  text-transform: uppercase;
  transition: all .2s ease;
  @include font(25px, 29px, 400);

  &:hover {
    transform: scale(1.01);
  }

  @media (max-width: $breakpoint-xs) {
    padding: 9px 14px;
    @include font(14px, 16px);
  }

  &--with-icon {
    padding-right: 80px;

    @media (max-width: $breakpoint-xs) {
      padding-right: 46px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 16px;
      @include round-icon;
      transform: translateY(-50%);

      @media (max-width: $breakpoint-xs) {
        width: 24px;
        height: 24px;
        background-size: 16px;
      }
    }
  }
}

