.news {
  position: relative;

  &__wrap {
    display: flex;
    gap: 45px 135px;

    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
    }

    @media (max-width: $breakpoint-xs) {
      display: unset;
      flex-direction: unset;
      gap: 0;
    }
  }

  &__img-wrap {
    width: 290px;
    height: 197px;
    margin-bottom: 25px;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (max-width: $breakpoint-sm) {
      margin-bottom: 0;
    }

    @media (max-width: $breakpoint-xs) {
      width: 100%;
      height: 260px;
      margin-bottom: 30px;
    }
  }

  &__item.swiper-slide {
    width: 290px;

    @media (max-width: $breakpoint-sm) {
      display: flex;
      width: auto;
      gap: 0 29px;
    }

    @media (max-width: $breakpoint-xs) {
      flex-direction: column;
    }
  }

  &__title {
    margin-bottom: 30px;
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    @include font(20px, 23px);

    &:after {
      content: "";
      margin-left: 9px;
      @include round-icon;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    position: absolute;
    width: 36px;
    height: 36px;
    z-index: 10;
    transform: translateY(-50%);
    border-radius: 50%;
    opacity: 0.7;
    cursor: pointer;
    background: #D9D9D9 url("../img/icons/icon-arrow-down.svg") no-repeat center center;
    transition: all .3s ease;

    @media (max-width: $breakpoint-xs) {
      display: block;
    }
  }

  .swiper-button-prev {
    left: 20px;
    top: 50%;
    transform: rotate(-90deg);

    &:hover {
      opacity: 1;
      transform: rotate(-90deg) scale(1.05);
    }
  }

  .swiper-button-next {
    right: 20px;
    top: 50%;
    transform: rotate(90deg);

    &:hover {
      opacity: 1;
      transform: rotate(90deg) scale(1.05);
    }
  }
}
