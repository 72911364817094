.about {

  li {
    list-style: none;
  }

  &__title {
    margin-bottom: 49px;

    @media (max-width: $breakpoint-xs) {
      margin-bottom: 25px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 37px 0;
  }

  &__item {
    position: relative;
    padding-left: 60px;
    max-width: 450px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      @include round-icon;
    }
  }

  &__advantage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
    gap: 32px;
    padding: 24px 32px;
    border-radius: 10px;
    background-color: $primary;
    color: white;

    @media (max-width: $breakpoint-xs) {
      margin-top: 25px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__advantage-value {
    @include font(50px, 60px, 700);
  }

  &__img {
    width: 395px;
    height: auto;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 10px;

    @media (max-width: $breakpoint-sm) {
      width: 100%;
      height: 531px;
    }

    @media (max-width: $breakpoint-xs) {
      width: 100%;
      height: 249px;
    }
  }
}
