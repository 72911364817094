@font-face {
  font-family: "europec";
  font-weight: 400;
  src: url("../fonts/EuropeC.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "europec";
  font-weight: 700;
  src: url("../fonts/EuropeC-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "europeExtendedc";
  font-weight: 400;
  src: url("../fonts/EuropeExtendedC.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "europeExtendedc";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/EuropeExtendedC-Italic.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "europeExtendedc";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/EuropeExtendedC-BoldItalic.ttf") format("truetype");
  font-display: swap;
}
body {
  font-family: "EuropeC", serif;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  hyphens: auto;
  overflow-wrap: break-word;
}

.text-h1 {
  font-size: 100px;
  line-height: 120px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-regular-h1 {
  font-size: 100px;
  line-height: 120px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-bold-h1 {
  font-size: 100px;
  line-height: 120px;
  letter-spacing: normal;
  font-weight: 700;
}

.text-xl {
  font-size: 60px;
  line-height: 70px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-regular-xl {
  font-size: 60px;
  line-height: 70px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-bold-xl {
  font-size: 60px;
  line-height: 70px;
  letter-spacing: normal;
  font-weight: 700;
}

.text-l {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-regular-l {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-bold-l {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: normal;
  font-weight: 700;
}

.text-m {
  font-size: 25px;
  line-height: 29px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-regular-m {
  font-size: 25px;
  line-height: 29px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-bold-m {
  font-size: 25px;
  line-height: 29px;
  letter-spacing: normal;
  font-weight: 700;
}

.text-s {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-regular-s {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-bold-s {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: normal;
  font-weight: 700;
}

.text-xs {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-regular-xs {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-bold-xs {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: normal;
  font-weight: 700;
}

.text-caption {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-regular-caption {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-bold-caption {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: normal;
  font-weight: 700;
}

@media screen and (max-width: 1439px) {
  .text-h1 {
    font-size: 100px;
    line-height: 120px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-regular-h1 {
    font-size: 100px;
    line-height: 120px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-bold-h1 {
    font-size: 100px;
    line-height: 120px;
    letter-spacing: normal;
    font-weight: 700;
  }
  .text-xl {
    font-size: 60px;
    line-height: 70px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-regular-xl {
    font-size: 60px;
    line-height: 70px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-bold-xl {
    font-size: 60px;
    line-height: 70px;
    letter-spacing: normal;
    font-weight: 700;
  }
}
@media screen and (max-width: 1199px) {
  .text-h1 {
    font-size: 69px;
    line-height: 83px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-regular-h1 {
    font-size: 69px;
    line-height: 83px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-bold-h1 {
    font-size: 69px;
    line-height: 83px;
    letter-spacing: normal;
    font-weight: 700;
  }
  .text-xl {
    font-size: 53px;
    line-height: 62px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-regular-xl {
    font-size: 53px;
    line-height: 62px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-bold-xl {
    font-size: 53px;
    line-height: 62px;
    letter-spacing: normal;
    font-weight: 700;
  }
}
@media screen and (max-width: 767px) {
  .text-h1 {
    font-size: 46px;
    line-height: 56px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-regular-h1 {
    font-size: 46px;
    line-height: 56px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-bold-h1 {
    font-size: 46px;
    line-height: 56px;
    letter-spacing: normal;
    font-weight: 700;
  }
  .text-xl {
    font-size: 29px;
    line-height: 34px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-regular-xl {
    font-size: 29px;
    line-height: 34px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-bold-xl {
    font-size: 29px;
    line-height: 34px;
    letter-spacing: normal;
    font-weight: 700;
  }
}
.header {
  position: relative;
  padding: 42px 0 42px 0;
  background-color: #F4F6FC;
}
.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1199px) {
  .header .container {
    flex-wrap: wrap;
  }
}
.header__menu {
  display: flex;
  flex-grow: 1;
}
@media (max-width: 1199px) {
  .header__menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: white;
  }
}
.header__menu-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 18px;
  list-style: none;
}
@media (max-width: 1199px) {
  .header__menu-list {
    display: flex;
    gap: 40px;
    margin: 0 0 40px 0;
    flex-direction: column;
  }
}
@media (max-width: 1199px) {
  .header__menu-item a {
    color: #434343;
  }
}
.header__menu-item:first-child {
  margin-left: auto;
}
.header__menu-item:last-child {
  margin-right: auto;
}
@media (max-width: 1199px) {
  .header__menu-item:first-child {
    margin-left: 0;
  }
}
.header__menu-container {
  width: 100%;
}
@media (max-width: 1199px) {
  .header__menu-container {
    justify-content: center;
    flex-direction: column;
  }
}
@media (max-width: 1199px) {
  .header__menu-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: auto;
    height: calc(100vh - 138px);
    padding: 50px 24px;
  }
}
.header__user-area {
  display: flex;
  flex-shrink: 0;
  gap: 0 10px;
  align-items: center;
  flex-wrap: wrap;
  max-width: 350px;
}
@media (max-width: 1199px) {
  .header__user-area {
    margin-left: auto;
  }
}
.header__user-area--sm {
  margin-left: 0;
}
.header__phone {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 30px;
}
.header__phone:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  background: url("../img/icons/phone-icon.svg") no-repeat center center;
}
@media (max-width: 1199px) {
  .header__phone {
    display: none;
  }
}
@media (max-width: 1199px) {
  .header__phone--sm {
    display: block;
  }
}
.header__email {
  position: relative;
  padding-left: 30px;
}
.header__email:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  background: url("../img/icons/mail-icon.svg") no-repeat center center;
}
.header__contacts {
  position: relative;
  display: none;
}
.header__contacts:before {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  width: 175px;
  height: 1px;
  background-color: #434343;
}
@media (max-width: 1199px) {
  .header__contacts {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 80px;
  }
}
.header__lang {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .header__lang {
    display: none;
  }
}
.header__lang--sm {
  display: none;
}
@media (max-width: 767px) {
  .header__lang--sm {
    display: flex;
  }
}
.header__lang-item {
  padding: 10px;
  background-color: transparent;
  color: #434343;
  border-radius: 4px;
}
.header__lang-item--active, .header__lang-item--active:visited {
  padding: 10px;
  background-color: #354B84;
  color: white;
}
.header .ac {
  margin-top: 0;
  border: none;
  background-color: transparent;
}
.header .ac .ac-trigger {
  display: none;
  width: 44px;
  height: 24px;
  padding: 0;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.header .ac .ac-trigger span {
  display: block;
  width: 100%;
  height: 4px;
  transition: 0.3s ease;
  background-color: #434343;
}
@media (max-width: 1199px) {
  .header .ac .ac-trigger {
    display: flex;
  }
}
.header .ac .ac-trigger::after {
  display: none;
}
.header .ac.is-active > .ac-header .ac-trigger::after {
  display: none;
}
.header .ac.is-active > .ac-header .ac-trigger span:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
}
.header .ac.is-active > .ac-header .ac-trigger span:nth-child(2) {
  opacity: 0;
}
.header .ac.is-active > .ac-header .ac-trigger span:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}

ul.header__menu-list {
  padding: 0 24px;
}
@media (max-width: 1199px) {
  ul.header__menu-list {
    padding: 0;
  }
}

.headslide {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 712px;
  margin: 45px 0 45px 0;
}
.headslide > * {
  position: relative;
  z-index: 1;
}
@media (max-width: 1199px) {
  .headslide {
    height: 506px;
  }
}
@media (max-width: 767px) {
  .headslide {
    height: auto;
  }
}
.headslide__background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../img/headslide.webp") no-repeat center center;
  background-size: cover;
  border-radius: 20px;
  z-index: -1;
}
@media (max-width: 767px) {
  .headslide__background-image {
    display: none;
  }
}
.headslide__title-wrap {
  position: relative;
  align-self: flex-end;
  width: 734px;
  padding: 13px 15px 0 15px;
  border-radius: 0 20px 0 20px;
  background-color: white;
}
@media (max-width: 1439px) {
  .headslide__title-wrap {
    width: 735px;
    padding: 23px 0 0 20px;
  }
}
@media (max-width: 1199px) {
  .headslide__title-wrap {
    width: 506px;
    padding: 0 0 0 20px;
  }
}
@media (max-width: 767px) {
  .headslide__title-wrap {
    width: 100%;
    padding: 0;
  }
}
.headslide__title-wrap h1 {
  color: #354B84;
}
.headslide__title-wrap:before, .headslide__title-wrap:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-top: 10px;
  border-right: 10px;
  border-bottom: 0;
  border-left: 0;
  background-color: transparent;
  border-radius: 0 30px 0 0;
  border-style: solid;
  border-color: white;
}
@media (max-width: 767px) {
  .headslide__title-wrap:before, .headslide__title-wrap:after {
    display: none;
  }
}
.headslide__title-wrap:before {
  top: -10px;
  left: -50px;
}
.headslide__title-wrap:after {
  bottom: -50px;
  right: -10px;
}
.headslide__title-text {
  max-width: 434px;
}
.headslide__content {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
}
@media (max-width: 767px) {
  .headslide__content {
    min-height: 283px;
    padding: 0 13px;
    background: url("../img/headslide.webp") no-repeat center center;
    background-size: cover;
    border-radius: 5px;
  }
}
.headslide__motto {
  align-self: flex-start;
  width: 420px;
  margin-top: 43px;
  color: white;
  font-family: "europec", serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 27px;
}
@media (max-width: 1199px) {
  .headslide__motto {
    width: 223px;
    margin-top: 21px;
    font-family: "europec", serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 17px;
  }
}
@media (max-width: 767px) {
  .headslide__motto {
    width: 178px;
    margin-top: 118px;
    font-family: "europec", serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;
  }
}
@media (max-width: 1199px) {
  .headslide__motto span {
    font-family: "europec", serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 16px;
  }
}
@media (max-width: 767px) {
  .headslide__motto span {
    font-family: "europec", serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 13px;
  }
}
.headslide__motto span:nth-child(1) {
  font-family: "europeExtendedc", serif;
  font-weight: 400;
}
.headslide__motto span:nth-child(2) {
  font-family: "europeExtendedc", serif;
  font-style: italic;
  font-weight: 700;
}
.headslide__buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-end;
  width: 720px;
  margin-top: 125px;
  gap: 24px 0;
}
@media (max-width: 1199px) {
  .headslide__buttons {
    width: 486px;
    margin-top: 55px;
  }
}
@media (max-width: 767px) {
  .headslide__buttons {
    width: 100%;
    margin-top: 27px;
    gap: 15px 0;
    align-self: flex-start;
  }
}

.about li {
  list-style: none;
}
.about__title {
  margin-bottom: 49px;
}
@media (max-width: 767px) {
  .about__title {
    margin-bottom: 25px;
  }
}
.about__list {
  display: flex;
  flex-direction: column;
  gap: 37px 0;
}
.about__item {
  position: relative;
  padding-left: 60px;
  max-width: 450px;
}
.about__item:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #354B84;
  background-image: url("../img/icons/right-small-up.svg");
  background-size: 24px;
  background-position: center center;
  background-repeat: no-repeat;
}
.about__advantage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  gap: 32px;
  padding: 24px 32px;
  border-radius: 10px;
  background-color: #354B84;
  color: white;
}
@media (max-width: 767px) {
  .about__advantage {
    margin-top: 25px;
    flex-direction: column;
    align-items: flex-start;
  }
}
.about__advantage-value {
  font-family: "europec", serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
}
.about__img {
  width: 395px;
  height: auto;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 10px;
}
@media (max-width: 1199px) {
  .about__img {
    width: 100%;
    height: 531px;
  }
}
@media (max-width: 767px) {
  .about__img {
    width: 100%;
    height: 249px;
  }
}

.history {
  margin-top: 23px;
  margin-bottom: 45px;
}
.history__accordion {
  flex-grow: 1;
}
.history__accordion-hidden {
  display: flex;
  flex-direction: column;
  gap: 56px;
}
.history__accordion-toggle p {
  margin-right: 13px;
}
.history__accordion-caption {
  margin-left: auto;
}
.history__img {
  height: 242px;
  object-fit: cover;
}
.history__item {
  position: relative;
  padding-left: 124px;
  width: 600px;
  overflow: visible;
}
@media (max-width: 1199px) {
  .history__item {
    width: 100%;
  }
}
.history__item:before {
  content: "";
  position: absolute;
  top: 90px;
  left: 40px;
  height: calc(100% - 60px);
  width: 5px;
  background-color: #354B84;
  border-radius: 10px;
}
@media (max-width: 1199px) {
  .history__item:before {
    top: 60px;
    left: 30px;
    height: calc(100% - 60px);
  }
}
.history__order {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  top: 0;
  left: 10px;
  color: white;
  border-radius: 100%;
  background-color: #354B84;
  font-family: "europec", serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 30px;
}
@media (max-width: 1199px) {
  .history__order {
    width: 44px;
    height: 44px;
  }
}
.history__order span {
  margin-top: 5px;
}
.history__date {
  margin-bottom: 16px;
}
.history__img {
  width: 395px;
  height: auto;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 10px;
}
@media (max-width: 1199px) {
  .history__img {
    width: 100%;
    height: 242px;
  }
}
.history .ac {
  display: flex;
  flex-direction: column;
  gap: 56px;
  margin-top: 0;
  border: none;
  background-color: transparent;
}
.history .ac .ac-trigger {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0;
  margin-bottom: 50px;
  font-family: "EuropeC", serif;
  font-weight: 400;
  color: #434343;
}
.history .ac .ac-trigger::after {
  content: "";
  position: relative;
  top: auto;
  right: auto;
  width: 24px;
  height: 24px;
  background: url("../img/icons/icon-arrow-down.svg") no-repeat center center;
  transform: rotate(180deg);
  transition: all 0.3s ease;
}
.history .ac.is-active > .ac-header .ac-trigger::after {
  content: "";
  transform: rotate(0deg);
}

.js-accordion--opened .history__accordion-toggle:after {
  transform: rotate(0);
}

.news {
  position: relative;
}
.news__wrap {
  display: flex;
  gap: 45px 135px;
}
@media (max-width: 1199px) {
  .news__wrap {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .news__wrap {
    display: unset;
    flex-direction: unset;
    gap: 0;
  }
}
.news__img-wrap {
  width: 290px;
  height: 197px;
  margin-bottom: 25px;
  border-radius: 10px;
  overflow: hidden;
}
.news__img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1199px) {
  .news__img-wrap {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .news__img-wrap {
    width: 100%;
    height: 260px;
    margin-bottom: 30px;
  }
}
.news__item.swiper-slide {
  width: 290px;
}
@media (max-width: 1199px) {
  .news__item.swiper-slide {
    display: flex;
    width: auto;
    gap: 0 29px;
  }
}
@media (max-width: 767px) {
  .news__item.swiper-slide {
    flex-direction: column;
  }
}
.news__title {
  margin-bottom: 30px;
}
.news__link {
  position: relative;
  display: flex;
  align-items: center;
  font-family: "europec", serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
}
.news__link:after {
  content: "";
  margin-left: 9px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #354B84;
  background-image: url("../img/icons/right-small-up.svg");
  background-size: 24px;
  background-position: center center;
  background-repeat: no-repeat;
}
.news .swiper-button-prev,
.news .swiper-button-next {
  display: none;
  position: absolute;
  width: 36px;
  height: 36px;
  z-index: 10;
  transform: translateY(-50%);
  border-radius: 50%;
  opacity: 0.7;
  cursor: pointer;
  background: #D9D9D9 url("../img/icons/icon-arrow-down.svg") no-repeat center center;
  transition: all 0.3s ease;
}
@media (max-width: 767px) {
  .news .swiper-button-prev,
  .news .swiper-button-next {
    display: block;
  }
}
.news .swiper-button-prev {
  left: 20px;
  top: 50%;
  transform: rotate(-90deg);
}
.news .swiper-button-prev:hover {
  opacity: 1;
  transform: rotate(-90deg) scale(1.05);
}
.news .swiper-button-next {
  right: 20px;
  top: 50%;
  transform: rotate(90deg);
}
.news .swiper-button-next:hover {
  opacity: 1;
  transform: rotate(90deg) scale(1.05);
}

.footer {
  padding: 28px 0;
  background-color: #F4F6FC;
  margin-top: 80px;
}
.footer a {
  transition: all 0.1s ease;
}
.footer a:hover {
  color: black;
}
@media (max-width: 1199px) {
  .footer {
    margin-top: 111px;
  }
}
@media (max-width: 767px) {
  .footer {
    margin-top: 50px;
  }
}
.footer .container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 27px 95px;
}
@media (max-width: 1199px) {
  .footer .container {
    padding: 0 15px 0 15px;
    grid-template-columns: repeat(3, 1fr);
    gap: 27px 59px;
  }
}
@media (max-width: 767px) {
  .footer .container {
    display: block;
  }
}
.footer__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 53px;
  border-radius: 4px;
  text-transform: uppercase;
  color: #354B84;
  background-color: white;
  font-family: "europeExtendedc", serif;
  font-weight: 400;
  letter-spacing: -3%;
}
.footer__icon-wrap {
  display: flex;
  flex-direction: column;
  gap: 19px 19px;
  width: 165px;
}
@media (max-width: 1199px) {
  .footer__icon-wrap {
    flex-direction: row;
    align-items: center;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 4;
  }
}
@media (max-width: 767px) {
  .footer__icon-wrap {
    margin-bottom: 42px;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  .footer__column {
    margin-bottom: 59px;
  }
  .footer__column:last-child {
    margin-bottom: 0;
  }
}
.footer__column:last-child {
  width: 169px;
}
.footer__column-title {
  margin-bottom: 15px;
}
.footer__list:not(:last-child) {
  margin-bottom: 15px;
}
.footer__list li:first-child {
  margin-top: 16px;
}
.footer__subitem-group {
  display: flex;
  flex-direction: column;
}
.footer__item {
  display: flex;
  margin-bottom: 15px;
  list-style: none;
}
.footer__item:last-child {
  margin-bottom: 0;
}
.footer__item--phone {
  position: relative;
  padding-left: 30px;
}
.footer__item--phone:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  background: url("../img/icons/phone-icon.svg") no-repeat center center;
}
.footer__item--mail {
  position: relative;
  padding-left: 30px;
}
.footer__item--mail:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 22px;
  height: 16px;
  transform: translateY(-50%);
  background: url("../img/icons/mail-icon.svg") no-repeat center center;
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  padding: 13px 16px;
  color: #434343;
  border-radius: 10px;
  background-color: white;
  text-transform: uppercase;
  transition: all 0.2s ease;
  font-family: "europec", serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 29px;
}
.button:hover {
  transform: scale(1.01);
}
@media (max-width: 767px) {
  .button {
    padding: 9px 14px;
    font-family: "europec", serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}
.button--with-icon {
  padding-right: 80px;
}
@media (max-width: 767px) {
  .button--with-icon {
    padding-right: 46px;
  }
}
.button--with-icon:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 16px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #354B84;
  background-image: url("../img/icons/right-small-up.svg");
  background-size: 24px;
  background-position: center center;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .button--with-icon:before {
    width: 24px;
    height: 24px;
    background-size: 16px;
  }
}

html, body {
  width: 100%;
  margin: 0;
  color: #434343;
  min-height: 100vh;
  font-family: "europec", serif;
  box-sizing: border-box;
}

body.fixed {
  overflow: hidden;
}

* {
  box-sizing: inherit;
}

a {
  color: #434343;
  text-decoration: none;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

h1 {
  font-family: EuropeC, serif;
  font-weight: 400;
}

.container {
  width: 100%;
  overflow: hidden;
  min-width: 375px;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 40px 0 40px;
}
@media screen and (max-width: 1199px) {
  .container {
    padding: 0 24px 0 24px;
  }
}
@media screen and (max-width: 767px) {
  .container {
    padding: 0 15px 0 15px;
  }
}

.section-title {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 45px;
  color: #434343;
  text-transform: uppercase;
  font-family: "europeExtendedc", serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 27px;
}
@media (max-width: 1199px) {
  .section-title {
    margin-bottom: 25px;
  }
}
.section-title:before {
  content: "";
  width: 96px;
  height: 1px;
  margin-right: 10px;
  background-color: #434343;
}
@media (max-width: 767px) {
  .section-title:before {
    width: 28px;
  }
}
.section-title:after {
  content: "";
  width: 454px;
  height: 1px;
  margin-left: auto;
  background-color: #434343;
}
@media (max-width: 1199px) {
  .section-title:after {
    width: 96px;
  }
}
@media screen and (max-width: 767px) {
  .section-title {
    font-family: "europec", serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 14px;
  }
}

.two-column {
  display: flex;
  gap: 0 30px;
}
@media (max-width: 1199px) {
  .two-column {
    flex-direction: column;
    gap: 59px 30px;
  }
}
@media (max-width: 767px) {
  .two-column {
    gap: 25px 30px;
  }
}
.two-column--reverse {
  flex-direction: row-reverse;
}
@media (max-width: 1199px) {
  .two-column--reverse {
    flex-direction: column;
  }
}