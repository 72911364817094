@mixin round-icon {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: $primary;
  background-image: url('../img/icons/right-small-up.svg');
  background-size: 24px;
  background-position: center center;
  background-repeat: no-repeat;
}
