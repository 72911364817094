.header {
  position: relative;
  padding: 42px 0 42px 0;
  background-color: $light-blue;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $breakpoint-sm) {
      flex-wrap: wrap;
    }
  }

  &__menu {
    display: flex;
    flex-grow: 1;

    @media (max-width: $breakpoint-sm) {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 10;
      background-color: white;
    }
  }

  &__menu-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    gap: 18px;
    list-style: none;

    @media(max-width: $breakpoint-sm) {
      display: flex;
      gap: 40px;
      margin: 0 0 40px 0;
      flex-direction: column;
    }
  }

  &__menu-item a {
    @media(max-width: $breakpoint-sm) {
      color: $dark;
    }
  }

  &__menu-item {
    &:first-child {
      margin-left: auto;
    }

    &:last-child {
      margin-right: auto;
    }

    @media(max-width: $breakpoint-sm) {
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__menu-container {
    width: 100%;

    @media(max-width: $breakpoint-sm) {
      justify-content: center;
      flex-direction: column;
    }
  }

  &__menu-inner {
    @media (max-width: $breakpoint-sm) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow: auto;
      height: calc(100vh - 138px);
      padding: 50px 24px;
    }
  }

  &__user-area {
    display: flex;
    flex-shrink: 0;
    gap: 0 10px;
    align-items: center;
    flex-wrap: wrap;
    max-width: 350px;

    @media(max-width: $breakpoint-sm) {
      margin-left: auto;
    }
  }

  &__user-area--sm {
    margin-left: 0;
  }

  &__phone {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 30px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 24px;
      height: 24px;
      transform: translateY(-50%);
      background: url("../img/icons/phone-icon.svg") no-repeat center center;
    }

    @media(max-width: $breakpoint-sm) {
      display: none;
    }

    &--sm {
      @media(max-width: $breakpoint-sm) {
        display: block;
      }
    }
  }

  &__email {
    position: relative;
    padding-left: 30px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 24px;
      height: 24px;
      transform: translateY(-50%);
      background: url("../img/icons/mail-icon.svg") no-repeat center center;
    }
  }

  &__contacts {
    position: relative;
    display: none;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 40px;
      width: 175px;
      height: 1px;
      background-color: $dark;
    }

    @media (max-width: $breakpoint-sm) {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-top: 80px;
    }
  }

  &__lang {
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-xs) {
      display: none;
    }

    &--sm {
      display: none;

      @media (max-width: $breakpoint-xs) {
        display: flex;
      }
    }
  }

  &__lang-item {
    padding: 10px;
    background-color: transparent;
    color: $dark;
    border-radius: 4px;
  }

  &__lang-item--active,
  &__lang-item--active:visited {
    padding: 10px;
    background-color: $primary;
    color: white;
  }

  .ac {
    margin-top: 0;
    border: none;
    background-color: transparent;
  }

  .ac .ac-trigger {
    display: none;
    width: 44px;
    height: 24px;
    padding: 0;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    span {
      display: block;
      width: 100%;
      height: 4px;
      transition: 0.3s ease;
      background-color: $dark;
    }

    @media (max-width: $breakpoint-sm) {
      display: flex;
    }
  }

  .ac .ac-trigger::after {
    display: none;
  }

  .ac.is-active > .ac-header .ac-trigger::after {
    display: none;
  }

  .ac.is-active > .ac-header .ac-trigger {
    span:nth-child(1) {
      transform: translateY(10px) rotate(45deg);
    }
    span:nth-child(2) {
      opacity: 0;
    }
    span:nth-child(3) {
      transform: translateY(-10px) rotate(-45deg);
    }
  }
}

ul.header__menu-list {
  padding: 0 24px;

  @media (max-width: $breakpoint-sm) {
    padding: 0;
  }
}
