.headslide {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 712px;
  margin: 45px 0 45px 0;

  & > * {
    position: relative;
    z-index: 1;
  }

  @media (max-width: $breakpoint-sm) {
    height: 506px;
  }

  @media (max-width: $breakpoint-xs) {
    height: auto;
  }

  &__background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../img/headslide.webp") no-repeat center center;
    background-size: cover;
    border-radius: 20px;
    z-index: -1;

    @media (max-width: $breakpoint-xs) {
      display: none;
    }
  }

  &__title-wrap {
    position: relative;
    align-self: flex-end;
    width: 734px;
    padding: 13px 15px 0 15px;
    border-radius: 0 20px 0 20px;
    background-color: white;

    @media (max-width: $breakpoint-md) {
      width: 735px;
      padding: 23px 0 0 20px;
    }

    @media (max-width: $breakpoint-sm) {
      width: 506px;
      padding: 0 0 0 20px;
    }

    @media (max-width: $breakpoint-xs) {
      width: 100%;
      padding: 0;
    }

    h1 {
      color: $primary;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 50px;
      height: 50px;
      border-top: 10px;
      border-right: 10px;
      border-bottom: 0;
      border-left: 0;
      background-color: transparent;
      border-radius: 0 30px 0 0;
      border-style: solid;
      border-color: white;

      @media (max-width: $breakpoint-xs) {
        display: none;
      }
    }

    &:before {
      top: -10px;
      left: -50px;
    }

    &:after {
      bottom: -50px;
      right: -10px;
    }
  }

  &__title-text {
    max-width: 434px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    @media (max-width: $breakpoint-xs) {
      min-height: 283px;
      padding: 0 13px;
      background: url("../img/headslide.webp") no-repeat center center;
      background-size: cover;
      border-radius: 5px;
    }
  }

  &__motto {
    align-self: flex-start;
    width: 420px;
    margin-top: 43px;
    color: white;
    @include font(30px, 27px);

    @media (max-width: $breakpoint-sm) {
      width: 223px;
      margin-top: 21px;
      @include font(18px, 17px);
    }

    @media (max-width: $breakpoint-xs) {
      width: 178px;
      margin-top: 118px;
      @include font(15px, 14px);
    }

    span {
      @media (max-width: $breakpoint-sm) {
        @include font(18px, 16px);
      }

      @media (max-width: $breakpoint-xs) {
        @include font(15px, 13px);
      }
    }

    span:nth-child(1) {
      font-family: "europeExtendedc", serif;
      font-weight: 400;
    }

    span:nth-child(2) {
      font-family: "europeExtendedc", serif;
      font-style: italic;
      font-weight: 700;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-end;
    width: 720px;
    margin-top: 125px;
    gap: 24px 0;

    @media (max-width: $breakpoint-sm) {
      width: 486px;
      margin-top: 55px;
    }

    @media (max-width: $breakpoint-xs) {
      width: 100%;
      margin-top: 27px;
      gap: 15px 0;
      align-self: flex-start;
    }
  }
}
