@font-face {
  font-family: 'europec';
  font-weight: 400;
  src: url('../fonts/EuropeC.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'europec';
  font-weight: 700;
  src: url('../fonts/EuropeC-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'europeExtendedc';
  font-weight: 400;
  src: url('../fonts/EuropeExtendedC.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'europeExtendedc';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/EuropeExtendedC-Italic.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'europeExtendedc';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/EuropeExtendedC-BoldItalic.ttf') format('truetype');
  font-display: swap;
}
