@import "varaibles";

body {
  font-family: 'EuropeC', serif;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  hyphens: auto;
  overflow-wrap: break-word;
}

$text-weights: (
  regular: 400,
  bold: 700,
);

.text-h1 {
  font-size: 100px;
  line-height: 120px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-regular-h1 {
  font-size: 100px;
  line-height: 120px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-bold-h1 {
  font-size: 100px;
  line-height: 120px;
  letter-spacing: normal;
  font-weight: 700;
}

.text-xl {
  font-size: 60px;
  line-height: 70px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-regular-xl {
  font-size: 60px;
  line-height: 70px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-bold-xl {
  font-size: 60px;
  line-height: 70px;
  letter-spacing: normal;
  font-weight: 700;
}

.text-l {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-regular-l {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-bold-l {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: normal;
  font-weight: 700;
}

.text-m {
  font-size: 25px;
  line-height: 29px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-regular-m {
  font-size: 25px;
  line-height: 29px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-bold-m {
  font-size: 25px;
  line-height: 29px;
  letter-spacing: normal;
  font-weight: 700;
}

.text-s {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-regular-s {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-bold-s {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: normal;
  font-weight: 700;
}

.text-xs {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-regular-xs {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-bold-xs {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: normal;
  font-weight: 700;
}

.text-caption {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-regular-caption {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: normal;
  font-weight: 400;
}

.text-bold-caption {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: normal;
  font-weight: 700;
}

@media screen and (max-width: $breakpoint-md) {
  .text-h1 {
    font-size: 100px;
    line-height: 120px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-regular-h1 {
    font-size: 100px;
    line-height: 120px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-bold-h1 {
    font-size: 100px;
    line-height: 120px;
    letter-spacing: normal;
    font-weight: 700;
  }
  .text-xl {
    font-size: 60px;
    line-height: 70px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-regular-xl {
    font-size: 60px;
    line-height: 70px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-bold-xl {
    font-size: 60px;
    line-height: 70px;
    letter-spacing: normal;
    font-weight: 700;
  }
}
@media screen and (max-width: $breakpoint-sm) {
  .text-h1 {
    font-size: 69px;
    line-height: 83px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-regular-h1 {
    font-size: 69px;
    line-height: 83px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-bold-h1 {
    font-size: 69px;
    line-height: 83px;
    letter-spacing: normal;
    font-weight: 700;
  }
  .text-xl {
    font-size: 53px;
    line-height: 62px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-regular-xl {
    font-size: 53px;
    line-height: 62px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-bold-xl {
    font-size: 53px;
    line-height: 62px;
    letter-spacing: normal;
    font-weight: 700;
  }
}
@media screen and (max-width: $breakpoint-xs) {
  .text-h1 {
    font-size: 46px;
    line-height: 56px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-regular-h1 {
    font-size: 46px;
    line-height: 56px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-bold-h1 {
    font-size: 46px;
    line-height: 56px;
    letter-spacing: normal;
    font-weight: 700;
  }
  .text-xl {
    font-size: 29px;
    line-height: 34px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-regular-xl {
    font-size: 29px;
    line-height: 34px;
    letter-spacing: normal;
    font-weight: 400;
  }
  .text-bold-xl {
    font-size: 29px;
    line-height: 34px;
    letter-spacing: normal;
    font-weight: 700;
  }
}

@mixin font($fontSize: 25px, $lineHeight: 29px, $fontWeight: 400, $fontFamily: 'europec') {
  font-family: $fontFamily, serif;
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: $lineHeight;
}


