.footer {
  padding: 28px 0;
  background-color: $light-blue;
  margin-top: 80px;

  a {
    transition: all .1s ease;

    &:hover {
      color: black;
    }
  }

  @media (max-width: $breakpoint-sm) {
    margin-top: 111px;
  }

  @media (max-width: $breakpoint-xs) {
    margin-top: 50px;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 27px 95px;

    @media (max-width: $breakpoint-sm) {
      padding: 0 15px 0 15px;
      grid-template-columns: repeat(3, 1fr);
      gap: 27px 59px;
    }

    @media (max-width: $breakpoint-xs) {
      display: block;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 53px;
    border-radius: 4px;
    text-transform: uppercase;
    color: $primary;
    background-color: white;
    font-family: "europeExtendedc", serif;
    font-weight: 400;
    letter-spacing: -3%;
  }

  &__icon-wrap {
    display: flex;
    flex-direction: column;
    gap: 19px 19px;
    width: 165px;

    @media (max-width: $breakpoint-sm) {
      flex-direction: row;
      align-items: center;
      width: 100%;
      grid-column-start: 1;
      grid-column-end: 4;
    }

    @media (max-width: $breakpoint-xs) {
      margin-bottom: 42px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__column {
    @media (max-width: $breakpoint-xs) {
      margin-bottom: 59px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:last-child {
      width: 169px;
    }
  }

  &__column-title {
    margin-bottom: 15px;
  }

  &__list {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    li:first-child {
      margin-top: 16px;
    }
  }

  &__subitem-group {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    margin-bottom: 15px;
    list-style: none;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item--phone {
    position: relative;
    padding-left: 30px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 24px;
      height: 24px;
      transform: translateY(-50%);
      background: url("../img/icons/phone-icon.svg") no-repeat center center;
    }
  }

  &__item--mail {
    position: relative;
    padding-left: 30px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 22px;
      height: 16px;
      transform: translateY(-50%);
      background: url("../img/icons/mail-icon.svg") no-repeat center center;
    }
  }
}
